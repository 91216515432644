<template>
  <div>
    <h1 class="mt-5 display-3"> Pups</h1>
    <Koppel />
  </div>
</template>
<script>
import Koppel from '@/components/Pups/koppel.vue';

export default {
  name: 'Pups',
  components: {
    Koppel,
  },
};
</script>
