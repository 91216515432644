<template>
  <v-container fluid grid-list-md text-xs-center mb-5>
    <v-layout row wrap align-center justify-center>
      <v-flex xs8 mb-5 mt-5>
        <div class="news">
          <template v-for="(newsItem) in this.newsItems">
            <div :key="newsItem.title" class="newsItem">
              <strong><i>{{newsItem.date}}</i>{{newsItem.title}}</strong>
              <span v-html="newsItem.subtitle" />
            </div>
          </template>
        </div>
      </v-flex>
      <v-layout
        mt-5
        row
        wrap
        justify-center
        v-for="dog in GetDogs"
        :key="dog.id"
      >
        <v-flex :class="mobile">
          <!-- mr-5 ml-5 -->
          <v-card elevation="0" class="dog">
            <v-card-title class="justify-center">
              <h3 class="headline">
                {{ dog.name }}
              </h3>
            </v-card-title>
            <v-card-text flat class="text text-justify-center">
              <li v-if="dog.birthdate">
                <span>Geboren: </span><span>{{ dog.birthdate }}</span>
              </li>
              <li v-if="dog.shoulderheight">
                <span>Schouder hoogte: </span>
                <span>{{ dog.shoulderheight }}</span>
              </li>
              <li v-if="dog.back">
                <span>Rug: </span><span>{{ dog.back }}</span>
              </li>
              <li v-if="dog.ataxia">
                <span>Ataxia: </span><span>{{ dog.ataxia }}</span>
              </li>
              <li v-if="dog.certificate">
                <span> Certificaten: </span>
                <span>
                  {{ dog.certificate }}
                </span>
              </li>
              <li v-if="dog.diploma">
                <span>Diploma: </span><span>{{ dog.diploma }}</span>
              </li>
              <li v-if="dog.training">
                <span>Training: </span>
                <span>{{ dog.training }}</span>
              </li>
              <li v-if="dog.birthdate">
                <span>NHSB: </span><span>{{ dog.nhsb }}</span>
              </li>
            </v-card-text>
            <v-layout>
              <v-flex xs12>
                <img
                  alt="image of dog"
                  :src="dog.pictureurl"
                  width="100%"
                  :class="{ image: dog.nhsb === '3205536' }"/>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-btn
                      v-show="dog.stamboom"
                      block
                      class=""
                      color="primary"
                      :href="dog.stamboom"
                      target="_blank"
                      >Stamboom
                    </v-btn>
                    <v-btn
                      v-if="dog.youtubevideo !== ''"
                      block
                      class=""
                      color="primary"
                      :href="dog.youtubevideo"
                      target="_blank"
                      >Impressie Video
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import dogs from '../../data/dogs';
import news from '../../data/news';

export default {
  data() {
    return {
      UnfilterdOurdogs: dogs,
      Ourdogs: [],
      activeTeef: Object,
      activeReu: Object,
      showDogDividerX: 0,
      newsItems: news.newsItems,
    };
  },
  computed: {
    GetDogs() {
      return this.Ourdogs;
    },
    RederOnce() {
      return this.Ourdogs;
    },
    mobile() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'xs6';
      }
      return 'xs12';
    },
  },
  mounted() {
    // bind filterd data
    this.activeTeef = this.GetactiveTeef();
    this.activeReu = this.GetactiveReu();
    // push filtered data to array
    this.CombineActiveDogs();
  },
  methods: {
    GetactiveReu() {
      this.activeReu = this.UnfilterdOurdogs.reuen.filter(d => d.active);
      this.showDogDividerX = 2;
      this.activeReu.showDogDividerX = this.showDogDividerX;
      return this.activeReu;
    },
    GetactiveTeef() {
      this.activeTeef = this.UnfilterdOurdogs.teven.filter(d => d.active);
      this.showDogDividerX = 1;
      this.activeTeef.showDogDividerX = this.showDogDividerX;
      return this.activeTeef;
    },

    CombineActiveDogs() {
      this.Ourdogs.push(
        this.activeTeef[0],
        this.activeReu[0],
        this.activeReu[1],
        this.activeTeef[1],
      );
    },
  },
};
</script>
<style lang="less" scoped>
.dog {
  background-color: #fafafa;
  //  background-color: red;
  .text {
    height: 225px;
    font-size: 16px;
  }
}

.image {
  max-width: 500px;
}

.pups {
  max-width: 300px;
  justify-content: space-around;
}

.headline {
  padding-top: 10px;
}

.news {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 1rem;
}

.news .newsItem {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #858585;
}

.news .newsItem strong {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

</style>
